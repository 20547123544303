html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  display: flex;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
}

#root {
  flex: 1;
}
